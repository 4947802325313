import { useEffect, useState } from "react";
import { Navigate, useLocation, useRoutes } from "react-router-dom";
import { useAuthContext } from "../auth/useAuthContext";
// auth
import GuestGuard from "../auth/GuestGuard";
// config
import AuthGuard from "../auth/AuthGuard";
import { getRole } from "../auth/utils";
import Unsubscribe from "../pages/Unsubscribe";
import { RoleType } from "../redux/common/common";
import { resetToggleSideMenu, toggleSidemenuReserve } from "../utils/helper";
import {
  AppointmentFeedbackClient,
  AskQuestions,
  AttorneyAppointments,
  AttorneyNotification,
  AttorneyProfile,
  ClientPayment,
  ClientPaymentSuccess,
  ClientProfile,
  ForgotPassword,
  ForgotPasswordOptions,
  ForgotPasswordOTP,
  LeadClaimSuccess,
  LeadsDetail,
  LoginPage,
  MainLayout,
  MyAppointments,
  MyQuestions,
  ResetPassword,
  SignUpPage,
  UserConsent,
  VerifyOTP,
} from "./elements";
import {
  APPOINTMENT_FEEDBACK,
  CLIENT_PAYMENT,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_OPTIONS,
  FORGOT_PASSWORD_OTP,
  LEAD_CLAIMED_SUCCESSFULLY,
  PAYMENT_SUCCESS,
  RESET_PASSWORD,
  ROOT_IMMIGRATION_QUESTION,
  ROOT_LOGIN,
  SIGN_UP,
  Tabs,
  USER_CONSENT,
  VERIFY_OTP,
} from "./paths";

const defaultPath = `${ROOT_IMMIGRATION_QUESTION}/${Tabs.HOME}`;
const Router: React.FC = () => {
  const { needDetail } = useAuthContext();
  const role = getRole();
  const [pathAfterLogin, setPathAfterLogin] = useState("");
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === `${ROOT_IMMIGRATION_QUESTION}`) {
      resetToggleSideMenu();
    }
    toggleSidemenuReserve();
  }, [location]);

  useEffect(() => {
    switch (role) {
      case RoleType.CLIENT:
        needDetail
          ? setPathAfterLogin(
              `${ROOT_IMMIGRATION_QUESTION}/${Tabs.CLIENT_PROFILE}`,
            )
          : setPathAfterLogin(`${ROOT_IMMIGRATION_QUESTION}/${Tabs.HOME}`);
        break;
      case RoleType.ATTORNEY:
        needDetail
          ? setPathAfterLogin(
              `${ROOT_IMMIGRATION_QUESTION}/${Tabs.ATTORNEY_PROFILE}`,
            )
          : setPathAfterLogin(
              `${ROOT_IMMIGRATION_QUESTION}/${Tabs.ATTORNEY_NOTIFICATIONS}`,
            );
        break;
      default:
        setPathAfterLogin(defaultPath);
    }
  }, [role, needDetail, pathAfterLogin]);

  return useRoutes([
    {
      path: "/",
      children: [
        { path: "/unsubscribe", element: <Unsubscribe /> },
        { path: "/subscribe", element: <Unsubscribe /> },
        { element: <Navigate to={ROOT_LOGIN} replace />, index: true },
        {
          path: `${SIGN_UP}`,
          element: (
            <GuestGuard>
              <SignUpPage />
            </GuestGuard>
          ),
        },
        {
          path: `${ROOT_LOGIN}`,
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          path: `${FORGOT_PASSWORD_OPTIONS}`,
          element: (
            <GuestGuard>
              <ForgotPasswordOptions />
            </GuestGuard>
          ),
        },
        {
          path: `${FORGOT_PASSWORD}`,
          element: (
            <GuestGuard>
              <ForgotPassword />
            </GuestGuard>
          ),
        },
        {
          path: `${FORGOT_PASSWORD_OTP}`,
          element: (
            <GuestGuard>
              <ForgotPasswordOTP />
            </GuestGuard>
          ),
        },
        {
          path: `${VERIFY_OTP}`,
          element: (
            <GuestGuard>
              <VerifyOTP />
            </GuestGuard>
          ),
        },
        {
          path: `${RESET_PASSWORD}`,
          element: <ResetPassword />,
        },
        {
          path: `${CLIENT_PAYMENT}`,
          element: (
            <GuestGuard>
              <ClientPayment />
            </GuestGuard>
          ),
        },
        {
          path: `${PAYMENT_SUCCESS}`,
          element: (
            <GuestGuard>
              <ClientPaymentSuccess />
            </GuestGuard>
          ),
        },
        {
          path: `${LEAD_CLAIMED_SUCCESSFULLY}`,
          element: <LeadClaimSuccess />,
        },
        {
          path: `${APPOINTMENT_FEEDBACK}`,
          element: <AppointmentFeedbackClient />,
        },
        {
          path: `${USER_CONSENT}`,
          element: <UserConsent />,
        },
      ],
    },

    {
      path: `${ROOT_IMMIGRATION_QUESTION}`,
      children: [
        { element: <Navigate to={pathAfterLogin} replace />, index: true },
        {
          path: `${ROOT_IMMIGRATION_QUESTION}/${Tabs.HOME}`,
          element: (
            <AuthGuard>
              <MainLayout />
            </AuthGuard>
          ),
        },
        {
          path: `${ROOT_IMMIGRATION_QUESTION}/${Tabs.ASK_QUESTION}`,
          element: <AskQuestions />,
        },
        {
          path: `${ROOT_IMMIGRATION_QUESTION}/${Tabs.CLIENT_PROFILE}`,
          element: <ClientProfile />,
        },
        {
          path: `${ROOT_IMMIGRATION_QUESTION}/${Tabs.MY_QUESTIONS}`,
          element: <MyQuestions />,
        },
        {
          path: `${ROOT_IMMIGRATION_QUESTION}/${Tabs.ATTORNEY_PROFILE}`,
          element: <AttorneyProfile />,
        },
        {
          path: `${ROOT_IMMIGRATION_QUESTION}/${Tabs.ATTORNEY_NOTIFICATIONS}`,
          element: <AttorneyNotification />,
        },
        {
          path: `${ROOT_IMMIGRATION_QUESTION}/${Tabs.LEADS_DETAILS}`,
          element: <LeadsDetail />,
        },
        {
          path: `${ROOT_IMMIGRATION_QUESTION}/${Tabs.MY_APPOINTMENTS}`,
          element: <MyAppointments />,
        },
        {
          path: `${ROOT_IMMIGRATION_QUESTION}/${Tabs.ATTORNEY_APPOINTMENTS}`,
          element: <AttorneyAppointments />,
        },
      ],
    },
  ]);
};

export default Router;
